import { useEffect, useState } from "react";
import axios from 'axios';
import { Calendar } from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import './Calendar.css'


function CalendarEdgeV2(props) {
    const [day, setDay] = useState(null);
    const [hour, setHour] = useState(null);
    const [hours, setHours] = useState(null);
    const [loading, setLoading] = useState(true);

    const [date, setDate] = useState(new Date());

    var dateMax = new Date();
    dateMax.setDate(dateMax.getDate() + 60);

    const privateToken = "pt_secret_b3e46a8a70fa3740b5110cd1c91c09da";
    const publicToken = "pt_01GS8DWX34MX9GQBNK48X8KTS0";
    const userURI = "https://api.calendly.com/users/1ba5dac0-774c-4878-9910-ce653973b1ff";
    const eventURI = "https://api.calendly.com/event_types/4eb88d36-4144-4010-9536-085cd39dbda5";

    const [workingDays, setWorkingDays] = useState("");
    //console.log(dateMax,date);
    // Get all scheduled events by day
    useEffect(() => {
        if (props.grabData !== "") {
            setLoading(true);

            const fetchData = async () => {
                try {
                    const { data: response } = await axios.post(
                        'https://api.savvycal.com/v1/events',
                        {
                            headers: {
                                "Authorization": "Bearer " + privateToken,
                                "Content-Type": 'application/json'
                            }

                        }
                    );
                    console.log(response);
                    //setWorkingDays(response.resource.rules);
                } catch (error) {
                    console.error(error.message);
                }
            }

            fetchData();
        }
    }, [props.grabData]);

    const [duration, setDuration] = useState("");
    // Get meetings duration
    useEffect(() => {
        if (workingDays !== "") {
            setLoading(true);

            const fetchData = async () => {
                try {
                    const { data: response } = await axios.get(
                        eventURI,
                        {
                            headers: {
                                "Authorization": "Bearer " + privateToken,
                                "Content-Type": 'application/json'
                            }

                        }
                    );
                    setDuration(response.resource.duration);
                } catch (error) {
                    console.error(error.message);
                }
            }

            fetchData();
        }
    }, [workingDays]);

    // Get all scheduled meetings
    const [scheduled, setScheduled] = useState("");
    useEffect(() => {
        if (day !== null) {
            setLoading(true);
            const fetchData = async () => {
                try {
                    const { data: response } = await axios.get(
                        'https://api.calendly.com/scheduled_events?user=' + userURI + "&min_start_time=" + day.start + "&max_start_time=" + day.end,
                        {
                            headers: {
                                "Authorization": "Bearer " + privateToken,
                                "Content-Type": 'application/json'
                            }

                        }
                    );
                    //console.log(response)
                    //console.log(response.collection);
                    //setScheduled(response.collection);
                    var tmpArr = [];
                    response.collection.map((e, i) => {
                        var tmp = new Date(e.start_time);
                        tmp = String((tmp.getHours() * 3600) + (tmp.getMinutes() * 60)).toHHMM();
                        tmpArr.push(tmp);
                    });
                    setScheduled(tmpArr);
                    //console.log(response);
                } catch (error) {
                    console.error(error.message);
                }
            }

            fetchData();
            //console.log(day);
        }
    }, [day]);

    useEffect(() => {
        if (scheduled !== "") {
            setEdgeHours();
        }
    }, [scheduled]);

    function setEdgeDay(date) {
        var tmpDate = new Date(date.getTime() + 86390040);
        //console.log(date, tmpDate);
        setDay({ start: date, end: tmpDate });
    }

    function setEdgeHours() {
        var tmpArr = [];
        //var tmp = 0;
        //var tmpTime =0;
        workingDays[day.start.getDay()].intervals.map((e, i) => {
            //tmpArr.push();
            var zS = e.from.split(":");
            var zE = e.to.split(":");
            //var tmpTime = (zE[0] * 3600 + zS[1] * 60) - (zS[0] * 3600 + zS[1] * 60);
            for (var i = (zS[0] * 3600 + zS[1] * 60); i < (zE[0] * 3600 + zE[1] * 60); i = i + (duration * 60)) {
                if (i + (duration * 60) <= (zE[0] * 3600 + zE[1] * 60)) {
                    tmpArr.push(String(i).toHHMM());
                }
                //console.log("saf");
            }
            //console.log(e);                       
        });
        //tmpTime = (tmpTime / (duration)) / 60;
        //console.log(tmpTime);
        /*for(var i=0;i<=tmpTime;i++){

        }*/
        //scheduled
        //console.log(tmpArr);
        setHours({ all: tmpArr, scheduled: scheduled });
        //console.log({all:tmpArr,scheduled:scheduled});
    }

    String.prototype.toHHMM = function () {
        var sec_num = parseInt(this, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        return hours + ':' + minutes;
    }

    function reset(){
        setDay(null);
        setHours(null);
    }


    return (
        <div id="calendar">
            <h4>Planifier un appel découverte</h4>
            <h5>{duration} min</h5>
            {loading && <div id="loading"></div>}            
            {day === null && <Calendar
                minDetail="month"
                onChange={setDate}
                value={date}
                range
                maxDate={dateMax}
                minDate={new Date()}
                next2Label=""
                prev2Label=""
                calendarType="US"
                tileDisabled={({ date }) => date.getDay() === 6 || date.getDay() === 0}
                onClickDay={(value) => setEdgeDay(value)}
            />}
            {hours !== null &&
                <div className="availableHours">
                    <div className="availableHours-top">
                        <button onClick={()=>reset()} className="prev">Prev</button>
                    </div>
                    <div className="availableHours-bottom">
                        {
                            hours.all.map((el, i) => {
                                const disabled = hours.scheduled.includes(el) ? "disabled" : "";
                                return (
                                    <button
                                        key={i}
                                        className={"btn " + disabled}
                                        disabled={disabled}
                                    >
                                        {el}
                                    </button>
                                );
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default CalendarEdgeV2;