import './App.css';
import Vimeo from '@u-wave/react-vimeo';
import { useState } from 'react';
import CalendarEdge from './components/Calendar';
import { InlineWidget } from 'react-calendly';
import CalendarEdgeV2 from './components/CalendarV2';
import IframeResizer from 'iframe-resizer-react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import TurnRightIcon from '@mui/icons-material/TurnRight';

function App() {
  const [toggled, setToggled] = useState("");
  const [toggledVid, setToggledVid] = useState(true);
  const zePath = window.location.pathname;
  const search = window.location.search;
  const urlParams = new URLSearchParams(search);
  const dateIn = new Date(urlParams.get("event_start_time"));
  const dateOut = new Date(urlParams.get("event_end_time"));
  var minIn = dateIn.getMinutes();
  var minOut = dateOut.getMinutes();
  minIn = minIn < 10 ? '0' + minIn : minIn;
  minOut = minOut < 10 ? '0' + minOut : minOut;
  //console.log(date);

  function toggleVideo() {
    setToggledVid(!toggledVid);
    //VolumeEvent
  }

  function getZeMonth(month) {
    var mo;
    switch (month) {
      case 0:
        mo = "Janvier"
        break;
      case 1:
        mo = "Février"
        break;
      case 2:
        mo = "Mars"
        break;
      case 3:
        mo = "Avril"
        break;
      case 4:
        mo = "Mai"
        break;
      case 5:
        mo = "Juin"
        break;
      case 6:
        mo = "Juillet"
        break;
      case 7:
        mo = "Août"
        break;
      case 8:
        mo = "Septembre"
        break;
      case 9:
        mo = "Octobre"
        break;
      case 10:
        mo = "Novembre"
        break;
      case 11:
        mo = "Décembre"
        break;
    }
    return mo;
  }



  function resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }

  const vidToggled = !toggledVid ? "vidToggle" : "";
  return (
    <>
      {
        !zePath.includes("thankyou") ?
          <div className="app">
            <div id="header">
              <div onClick={() => toggleVideo()} className={'play ' + vidToggled}></div>
              <Vimeo
                video="800927246"
                id='800927246'
                showByline={false}
                responsive={true}
                controls={true}
                paused={toggledVid}
                showTitle={false}
                volume={1}
              />

            </div>
            <div id="content">
              <h1>ENTREZ DANS UNE TOUTE NOUVELLE DIMENSION</h1>
              <p>Vous recevez cette boîte, car vos produits nous inspirent et recèlent un potentiel unique que nous aimerions explorer avec vous.
                Nous sommes des créateurs de visuels et d'outils de configuration 3D facilitant la collaboration entre manufacturiers et distributeurs, tout en offrant une expérience d'achat à couper le souffle à votre clientèle.
                <br/><br/>Appuyez sur "<b>En apprendre plus</b>" pour planifier un appel découverte.<TurnRightIcon className='arrow'/></p>
                
              <button id="btn" className={toggled} onClick={() => setToggled("toggled")}>En apprendre plus</button>
              <div id="toggle" className={toggled}>
                <span className='separator'></span>
                <InlineWidget
                  url="https://calendly.com/edgedimension/30min"
                  styles={{
                    height: '1030px'
                  }}
                  onload={() => resizeIframe(this)}
                  pageSettings={{
                    backgroundColor: '000000',
                    hideEventTypeDetails: false,
                    hideLandingPageDetails: true,
                    primaryColor: 'ffffff',
                    textColor: 'ffffff'
                  }}
                />

              </div>
            </div>
          </div> :
          <div id="ty" className="app">
            <div id="header">
              <div onClick={() => toggleVideo()} className={'play ' + vidToggled}></div>
              <Vimeo
                video="800927246"
                id='800927246'
                showByline={false}
                responsive={true}
                controls={true}
                paused={toggledVid}
                showTitle={false}
                volume={1}
              />
            </div>
            <div className='top'>
              <h1>Merci!</h1>
              <div className='confirm'>
                <CalendarTodayRoundedIcon className='big' />
                <CheckRoundedIcon className='big check' />
              </div>

              <span className='separator'></span>
              <div className='cat'><PersonRoundedIcon /><span>{urlParams.get("invitee_full_name")}</span></div>
              <div className='cat'><QueryBuilderRoundedIcon /><span>{String(dateIn.getHours()) + "h" + minIn + " à " + String(dateOut.getHours()) + "h" + minOut}</span></div>
              <div className='cat'><CalendarMonthRoundedIcon /><span>{String(dateIn.getDate()) + " " + getZeMonth(dateIn.getMonth()) + " " + String(dateIn.getUTCFullYear())}</span></div>



            </div>
          </div>
      }
    </>
  );
}

export default App;
